@media (min-width:1600px) {

    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    .container-xxl {
        max-width: 1800px;
    }

    .row-cols-xxxl-10>* {
        flex: 0 0 auto !important;
        width: 10% !important;
    }

    .row-cols-xxxl-8>* {
        flex: 0 0 auto;
        width: 12.5%;
    }

    .row-cols-xxxl-5>* {
        flex: 0 0 auto !important;
        width: 20% !important;
    }

    .row-cols-xxxl-4>* {
        flex: 0 0 auto;
        width: 25%;
    }
}

@media (min-width:1400px) {
    .row-cols-xxl-8>* {
        flex: 0 0 auto;
        width: 12.5%;
    }
}

@media (max-width:1599.98px) {
    .blogOuter .blogImgOuter {
        height: 420px;
    }

    .productItem .price {
        font-size: 16px;
    }

    .productItem .productDetail .productFooter {
        flex-direction: column;
        gap: 4px !important;
        align-items: start !important;
    }

    .productItem .productDetail .productFooter a {
        width: 100%;
    }
}

@media (max-width:1399.98px) {
    .sliderContent {
        max-width: 600px;
    }

    .vehicleOuter {
        width: 175px;
    }

    .sliderContent .mainTitle {
        font-size: 40px;
    }

    .productsDetailOuter .productImg {
        height: 300px;
    }

    .owl-dots .owl-dot {
        width: 14px;
        height: 14px;
    }

    .blogOuter .blogImgOuter {
        height: 360px;
    }

    .quoteItem .quoteValue {
        font-size: 15px;
    }

    .quoteItem .quoteType {
        font-size: 14px;
    }

    .quoteItem .quoteDec {
        font-size: 14px;
    }

    .quoteDealerPro .imgOuter {
        width: 60px;
        height: 60px;
    }

    .quoteDealerPro .name {
        font-size: 15px;
    }

    .otherSliderMain .otherSliderItem {
        height: 160px;
    }

    .partItem .imgOuter {
        height: 165px;
    }
}

@media (max-width:1199.98px) {
    .sliderContent {
        margin: auto;
        right: 0;
        left: 0;
        text-align: center;
    }

    .vehicleOuter {
        width: 175px;
    }


    .sliderContent .mainTitle {
        font-size: 36px;
    }

    .sectionHeading .title {
        font-size: 20px;
    }

    .footerMain .bottomFooter .footerNav .navItem .navLink,
    .footerMain .bottomFooter .copyRight {
        font-size: 16px;
        line-height: 1.25;
    }

    .headerSearch .form-control {
        min-width: 280px;
    }

    .specialistWraper .specialistItem .imgOuter,
    .dealerWraper .specialistItem .imgOuter {
        width: 80px;
        height: 80px;
    }

    .specialistWraper .specialistItem .name {
        font-size: 15px;
    }

    .specialistItem .serviceCharge {
        font-size: 17px;
    }

    .serviceDetailOuter .serviceImage {
        height: 460px;
    }

    .productFullDetail .name {
        font-size: 17px;
    }

    .specialistProfileOuter .proCoverImg {
        height: 260px;
    }

    .seiviceCatalogueItem .serviceImg {
        width: 120px;
        height: 80px;
    }

    .seiviceCatalogueItem .serviceDetail .serviceTitle {
        font-size: 15px;
    }

    .seiviceCatalogueItem .serviceDetail .servicePrice {
        font-size: 17px;
    }

    .blogItem {
        border-radius: 10px;
    }

    .blogItem .blogImg {
        height: 150px;
        border-radius: 10px;
    }

    .blogItem .blogDetail .blogTitle {
        font-size: 16px;
    }

    .blogOuter .blogImgOuter {
        height: 320px;
    }

    .blogOuter .blogTitle {
        font-size: 20px;
    }

    .pageImgOuter {
        height: 260px;
    }

    .pageTitle {
        font-size: 60px;
    }

    .otherSliderMain .otherSliderItem {
        height: 180px;
    }
}

@media (max-width:991.98px) {
    .mainHeader .navbar-brand {
        height: 80px;
    }

    .vehicleOuter {
        width: 168px;
    }

    .mainHeader .profileMenu .profileImg {
        margin-bottom: 0;
    }

    .headerOffcanvas .nav-link .headerIcon {
        width: 24px;
        height: 24px;
        margin-bottom: 0;
    }

    .headerOffcanvas .nav-link::after,
    .mainHeader .profileMenu::after {
        display: none;
        margin: 0;
    }

    .headerOffcanvas .nav-link:hover,
    .headerOffcanvas .nav-link.active,
    .mainHeader .profileMenu:hover {
        letter-spacing: 1px;
    }

    .heroSliderMain .owl-nav .owl-prev,
    .heroSliderMain:hover .owl-nav .owl-prev,
    .serviceDetailOuter .owl-nav .owl-prev,
    .serviceDetailOuter:hover .owl-nav .owl-prev {
        left: 20px;
    }

    .heroSliderMain .owl-nav .owl-next,
    .heroSliderMain:hover .owl-nav .owl-next,
    .serviceDetailOuter .owl-nav .owl-next,
    .serviceDetailOuter:hover .owl-nav .owl-next {
        right: 20px;
    }

    .owl-dots .owl-dot {
        width: 11px;
        height: 11px;
        border: 1px solid #0AA9DD;
    }

    .owl-dots .owl-dot.active {
        width: 33px;
    }

    .sliderContent .mainTitle {
        font-size: 32px;
    }

    .sectionPading {
        padding: 16px 0;
    }

    .categoriesItem .categoriesImg {
        width: 80px;
        height: 80px;
    }

    .categorySection .owl-nav .owl-prev,
    .categorySection .owl-nav .owl-next {
        top: 22px;
    }

    .heroSliderMain .owl-nav .owl-prev,
    .heroSliderMain .owl-nav .owl-next,
    .serviceDetailOuter .owl-nav .owl-prev,
    .serviceDetailOuter .owl-nav .owl-next {
        width: 40px;
        height: 40px;
    }

    .specialistItem .specialistDetail .name {
        font-size: 16px;
    }

    .specialistItem .specialistDetail .dec {
        font-size: 14px;
    }

    .specialistItem .imgOuter {
        width: 50px;
        height: 50px;
    }

    .partItem .contentPart .partTitle {
        font-size: 14px;
    }

    .partItem .contentPart .partPrice {
        font-size: 16px;
    }

    .mainWraper {
        min-height: calc(100vh - 172px);
    }

    .vehicleItem .vehicleDetail .brand {
        font-size: 12px;
    }

    .vehicleItem .vehicleDetail .name {
        font-size: 14px;
    }

    .vehicleItem .vehicleDetail .year {
        font-size: 13px;
    }

    .vehicleItem .vehicleDetail .vehicleNumber {
        font-size: 16px;
    }

    .editBtn,
    .deleteBtn {
        width: 32px;
        height: 32px;
        padding: 6px !important;
    }

    .vehicleItem .imgOuter {
        width: 100px;
        height: 100px;
        border-radius: 6px;
    }

    .vehicleItem {
        padding: 12px !important;
        border-radius: 8px;
    }

    .serviceDetailOuter .serviceImage {
        height: 400px;
        min-height: 380px;
    }

    .productFullDetail .name {
        font-size: 15px;
    }

    .productFullDetail .brand,
    .productFullDetail .category {
        font-size: 13px;
    }

    .productFullDetail .proRating {
        font-size: 14px;
    }

    .productFullDetail .proPrice {
        font-size: 20px;
    }

    .proDec .title {
        font-size: 17px !important;
    }

    .proDec p {
        font-size: 15px;
    }

    .specialistProfileOuter .proCoverImg {
        height: 220px;
    }

    .chatMainOuter .chatMainInner .chatingSide {
        position: absolute;
        left: 100%;
        top: 0;
        width: 100%;
        z-index: 8;
        transition: ease-in-out .3s;
        background-color: #f6f9fa;
    }

    .chatMainOuter .chatMainInner .chatingSide.active {
        left: 0;
    }

    /* .chatMainOuter {
        max-height: calc(100vh - 220px);
    } */
    .notificationItem .imgOuter {
        width: 40px;
        height: 40px;
    }

    .notificationItem .title {
        font-size: 15px;
    }

    .notificationItem .notiTime {
        font-size: 13px;
    }

    .notificationItem .deleteBtn {
        width: 24px;
        height: 24px;
        padding: 4px !important;
    }

    .notificationItem .notificationDec {
        font-size: 14px;
        font-weight: 400;
    }

    .notificationItem .notificationReadMore {
        top: 40px;
    }

    .profileMainWrapper .profileMainOuter .imgOuter,
    .profileMainWrapper .editProfileMainOuter .profileChange {
        width: 90px;
        height: 90px;
    }

    .pageImgOuter {
        height: 200px;
    }

    .contentWrapper .pera {
        font-size: 16px;
    }

    .productAttribute .productDetail .title,
    .productAttribute .productDetail .value {
        font-size: 15px;
    }

    .otherSliderMain .otherSliderItem {
        height: 190px;
    }

    .partItem .imgOuter {
        height: 155px;
    }

    .footerMain .bottomFooter {
        padding: 12px 0 80px;
    }

    .faqMainSection .accordion-header button:not(.collapsed) {
        font-size: 16px;
        padding: 8px;
    }

    .faqMainSection .accordion-body .dec {
        font-size: 15px;
    }

    .faqMainSection .accordion-button::after {
        width: 2px;
        height: 14px;
        right: 18px;
    }

    .faqMainSection .accordion-button::before {
        width: 14px;
        height: 2px;
        border-radius: 50px;
        right: 12px;
    }
}

@media (max-width:767.98px) {
    .vehicleOuter {
        width: 130px;
    }

    .categoriesItem .categoriesImg img {
        overflow: hidden;
        border-radius: 50%;
    }

    .active.categoriesItem .categoriesImg:before {
        height: 25px;
        width: 25px;
    }

    .active.categoriesItem .categoriesImg:after {
        width: 6px;
        height: 10px;
        right: 9px;
        top: 6px;
    }

    .sliderContent .mainTitle {
        font-size: 26px;
    }

    .heroSliderMain .owl-dots {
        bottom: 20px;
    }

    .heroSliderMain .heroPera {
        font-size: 14px;
    }

    .btnStyle1,
    .btnLight {
        font-size: 15px;
        padding: 10px 16px;
    }

    .sectionHeading .title {
        font-size: 18px;
    }

    .brandsOuter .brandsItem {
        height: 80px;
        padding: 12px;
    }

    .footerMain .bottomFooter .footerNav .navItem .navLink,
    .footerMain .bottomFooter .copyRight {
        font-size: 14px;
    }

    .serviceDetailOuter .serviceImage {
        height: 340px;
        min-height: 280px;
    }

    .profileDetail .detail .name {
        font-size: 18px;
    }

    .specialistProfileOuter .proCoverImg {
        height: 180px;
    }

    .profileDetail .proImgOuter {
        width: 120px;
        height: 120px;
        margin-top: -102px;
    }

    .profileDetail .proImgOuter .bgDesign {
        left: -48px;
    }

    .favTabs .nav-item {
        width: calc(100% / 4);
    }

    .blogOuter .blogImgOuter {
        height: 280px;
    }

    .blogOuter .pera {
        font-size: 15px;
    }

    .chatMainOuter .chatMainInner .chatingSide .chatOuter .msgMain .msg.txtMsg {
        padding: 4px 8px;
        border-radius: 0 10px 10px;
    }

    .chatMainOuter .chatMainInner .chatingSide .chatOuter .msgMain.send .msgCol .msg.txtMsg {
        border-radius: 10px 0 10px 10px;
    }

    .profileMainWrapper .profileMainOuter .imgOuter,
    .profileMainWrapper .editProfileMainOuter .profileChange {
        width: 80px;
        height: 80px;
        margin: 0 auto;
    }

    .pageImgOuter {
        height: 160px;
    }

    .pageTitle {
        font-size: 48px;
    }

    .otherSliderMain .otherSliderItem {
        height: 160px;
    }
}

@media (max-width:575.98px) {

    .categoriesItem .categoriesName,
    .categoriesItem .dealerName {
        font-size: 15px;
        margin-top: 8px !important;
    }

    .sectionPading {
        padding: 8px 0;
    }

    .productItem {
        padding: 8px;
        border-radius: 8px;
    }

    .productItem .productImg {
        height: 140px;
        border-radius: 8px 8px 0 0;
    }

    .productItem .productDetail {
        padding-top: 8px;
    }

    .productItem .proTitle {
        font-size: 14px;
    }

    .productItem .price {
        font-size: 16px;
    }

    .productItem .btnStyle1 {
        padding: 8px;
        font-size: 12PX;
    }

    .specialistItem .specialistDetail .name {
        font-size: 14px;
    }

    .specialistItem .specialistDetail .dec {
        font-size: 12px;
    }

    .specialistItem .imgOuter {
        width: 42px;
        height: 42px;
    }

    .specialistItem {
        padding: 10px;
        border-radius: 8px;
    }

    .headerSearch .form-control {
        min-width: 200px;
    }

    .specialistWraper .specialistItem .imgOuter,
    .dealerWraper .specialistItem .imgOuter {
        width: 60px;
        height: 60px;
    }

    .serviceDetailOuter .serviceImage {
        height: 260px;
        min-height: 26px;
    }

    .serviceDetailOuter .serviceImage img {
        object-fit: cover;
        height: 100%;
    }

    .profileDetail .proImgOuter {
        width: 100px;
        height: 100px;
        margin-top: -51px;
    }

    .profileDetail .proImgOuter .bgDesign {
        left: -58px;
    }

    .profileDetail .proImg {
        border: 6px solid #fff;
    }

    .btnStyle1,
    .btnLight {
        font-size: 14px;
        padding: 8px 16px;
    }

    .favTabs .nav-link {
        border-bottom: 2px solid transparent;
        font-size: 14px;
        padding: 8px 8px;
    }

    .blogItem .blogDetail .dec {
        font-size: 14px;
    }

    .blogDate {
        font-size: 13px;
    }

    .blogOuter .blogTitle {
        font-size: 18px;
        font-weight: 600;
    }

    .quoteItem {
        padding: 12px !important;
        border-radius: 8px;
    }

    .quoteItem .quoteValue {
        font-size: 14px;
    }

    .quoteDealerPro .imgOuter {
        width: 50px;
        height: 50px;
    }

    .quoteDealerPro .name {
        font-size: 14px;
    }

    .quoteDealerPro .dec {
        font-size: 13px;
    }

    /* .chatMainOuter {
        max-height: calc(100vh - 204px);
    } */
    .chatMainOuter .chatMainInner .chatingSide .chatOuter .msgMain .msg.txtMsg {
        max-width: 90%;
    }

    .chatMainOuter .chatMainInner .chatingSide .chatOuter .msgMain .imgOuter {
        width: 28px;
        height: 28px;
    }

    .notificationItem {
        border-radius: 8px;
        padding: 12px !important;
    }

    .notificationItem .notificationReadMore {
        top: 54px;
    }

    .pageTitle {
        font-size: 40px;
    }

    .pageImgOuter {
        height: 140px;
    }

    .heroSliderMain {
        -webkit-mask-image: none;
        -webkit-mask-image: none;
    }


        /* .heroSliderMain .sliderImg {
            height: 230px;
        } */
.mainHeader.fixedTop .navbar-brand {
    height: 70px;
}
.owl-dots .owl-dot {
    width: 8px;
    height: 8px;
    border: 1px solid #0AA9DD;
}
.owl-dots .owl-dot.active {
    width: 20px;
}
.heroSliderMain .owl-dots {
    bottom: 4px;
}
    .heroSliderMain .sliderImg img {
        height: 100%;
        object-fit: fill;
    }
    .heroSliderMain .owl-nav{
        display: none;
    }


    .productAttribute .productDetail .title,
    .productAttribute .productDetail .value {
        font-size: 13px;
    }

    .productAttribute .form-check .form-check-label .colour {
        width: 30px;
        height: 30px;
    }

    .productAttribute .form-check .form-check-label .colour::before {
        width: 7px;
        height: 13px;
        border-bottom: 2px solid #fff;
        border-right: 2px solid #fff;
        top: -3px;
    }

    .partItem .imgOuter {
        height: 150px;
    }

    .bottomBarMain .nav-link {
        font-size: 13px;
        font-weight: 400;
    }

    .bottomBarMain .nav-link::after {
        height: 2px;
        margin-top: 2px;
    }

    .faqMainSection .accordion-header button:not(.collapsed) {
        font-size: 15px;
    }

    .faqMainSection .accordion-body .dec {
        font-size: 14px;
        line-height: 1.5;
        font-weight: 400;
    }
}

@media (max-width:480.98px) {
    .row-cols-xs-2>* {
        flex: 0 0 auto;
        width: 50%;
    }

    .row-cols-xs-1>* {
        flex: 0 0 auto;
        width: 100%;
    }

    .productsDetailOuter .productImg {
        height: 260px;
    }

    .blogOuter .blogImgOuter {
        height: 220px;
    }

    .notificationItem .imgOuter {
        width: 30px;
        height: 30px;
    }

    .pageTitle {
        font-size: 30px;
    }

    .partItem .imgOuter {
        height: 170px;
    }
}

@media (max-width:380.98px) {
    .categoriesItem .categoriesImg {
        width: 70px;
        height: 70px;
    }

    .categorySection .owl-nav .owl-prev,
    .categorySection .owl-nav .owl-next {
        top: 19px;
        width: 32px;
        height: 32px;
    }

    .productItem .productImg {
        height: 120px;
    }

    .productItem .productFooter {
        display: block !important;
    }

    .productItem .productFooter .price {
        margin-bottom: 8px;
    }

    .serviceDetailOuter .serviceImage {
        height: 240px;
        min-height: 24px;
    }

    .productsDetailOuter .productImg {
        height: 180px;
    }

    .specialistProfileOuter .proCoverImg {
        height: 150px;
    }

    .blogOuter .blogImgOuter {
        height: 200px;
    }

    .pageTitle {
        font-size: 24px;
    }

    .pageImgOuter {
        height: 100px;
    }

    .otherSliderMain .otherSliderItem {
        height: 120px;
    }

    .partItem .imgOuter {
        height: 150px;
    }

    .bottomBarMain .nav-link .headerIcon svg {
        width: 18px;
        height: 18px;
    }
}

@media (max-width:475px) {
    .col-6 {
        flex: 0 0 auto;
        width: 100%;
    }
}

@media (max-width:420px) {
    .chatProductDetailOuter.hide {
        top: -115.5px !important;
    }
}