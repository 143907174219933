body {
  background-color: #f6f9fa;
  font-family: "Open Sans", "Barlow", sans-serif;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #0aa9dd;
  border-radius: 50px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #0782ac;
}

.mainWraper {
  min-height: calc(100vh - 146px);
}

.mainHeader {
  transition: ease-in-out 0.3s;
}

.mainHeader.fixedTop {
  position: sticky;
  top: 0;
  z-index: 1005;
  background-color: #f6f9fa;
}

.mainHeader.fixedTop .navbar-brand {
  height: 80px;
}

.mainHeader .navbar-brand {
  height: 100px;
}

.headerOffcanvas .nav-link,
.mainHeader .profileMenu {
  color: #121212;
  font-size: 15px;
  font-weight: 600;
  transition: ease-in-out 0.3s;
}

.headerOffcanvas .nav-link:hover,
.headerOffcanvas .nav-link.active,
.mainHeader .profileMenu:hover {
  color: #0aa9dd;
}

.headerOffcanvas .nav-link .headerIcon {
  width: 32px;
  height: 32px;
  margin-bottom: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.headerOffcanvas .nav-link .headerIcon svg.strockIcon path {
  stroke: #121212;
  transition: ease-in-out 0.3s;
}

.headerOffcanvas .nav-link .headerIcon svg path {
  fill: #121212;
  transition: ease-in-out 0.3s;
}

.headerOffcanvas .nav-link:hover .headerIcon svg path,
.headerOffcanvas .nav-link.active .headerIcon svg path {
  fill: #0aa9dd;
}

.headerOffcanvas .nav-link:hover .headerIcon svg.strockIcon path,
.headerOffcanvas .nav-link.active .headerIcon svg.strockIcon path {
  stroke: #0aa9dd;
}

.headerOffcanvas .nav-link::after,
.mainHeader .profileMenu::after {
  content: "";
  display: block;
  width: 0;
  height: 3px;
  background-color: transparent;
  border-radius: 50px;
  margin-top: 5px;
  transition: ease-in-out 0.3s;
}

.headerOffcanvas .nav-link.active::after {
  width: 140%;
  background-color: #0aa9dd;
}

.mainHeader .profileMenu .profileImg {
  width: 32px;
  height: 32px;
  margin-bottom: 3px;
}

.mainHeader .profileMenu .profileImg img {
  object-fit: cover;
  object-position: top center;
}

.mainHeader .dropdown .dropdown-toggle,
.mainHeader .dropdown .dropdown-toggle:active {
  background-color: transparent;
  border: none;
  box-shadow: none !important;
  padding: 0;
}

.mainHeader .dropdown .dropdown-toggle::after {
  display: none;
}

.mainHeader .dropdown .dropdown-menu,
.filterDrop .dropdown-menu {
  padding: 0;
  min-width: 280px;
  border: none;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  max-height: 460px;
  overflow: hidden auto;
}

.mainHeader .dropdown .dropdown-menu .dropdown-item,
.filterDrop .dropdown-menu .dropdown-item {
  font-size: 15px;
  font-weight: 500;
  padding: 12px 24px;
  display: flex;
  color: #121212;
  align-items: center;
  gap: 12px;
  transition: ease-in-out 0.3s;
}

.mainHeader .dropdown.langDrop .dropdown-menu {
  min-width: 200px;
}

.mainHeader .dropdown .dropdown-menu .dropdown-item svg {
  width: 22px;
  height: 22px;
}

.mainHeader .dropdown .dropdown-menu .dropdown-link {
  text-decoration: none;
}

.flagIcon {
  width: 30px;
  height: 30px;
  border-radius: 50px;
  overflow: hidden;
}

.flagIcon img {
  object-fit: cover;
  object-position: top center;
}

.mainHeader .dropdown .dropdown-menu .dropdown-item svg.strockIcon path {
  stroke: #121212;
  fill: transparent;
  transition: ease-in-out 0.3s;
}

.mainHeader
  .dropdown
  .dropdown-menu
  .dropdown-item:hover
  svg.strockIcon
  circle {
  transition: ease-in-out 0.3s;
}

.mainHeader .dropdown .dropdown-menu .dropdown-item:hover svg.strockIcon path {
  stroke: #0aa9dd;
  fill: transparent;
}

.mainHeader
  .dropdown
  .dropdown-menu
  .dropdown-item:hover
  svg.strockIcon
  circle {
  fill: #0aa9dd;
}

.mainHeader .dropdown .dropdown-menu .dropdown-item svg path {
  fill: #121212;
  transition: ease-in-out 0.3s;
}

.mainHeader .dropdown .dropdown-menu .dropdown-item:hover svg path {
  fill: #0aa9dd;
}

.mainHeader .dropdown .dropdown-menu .dropdown-item:hover,
.filterDrop .dropdown-menu .dropdown-item:hover {
  background-color: #0aa8dd1f;
  color: #0aa9dd;
  border-color: transparent !important;
}

.mainHeader .dropdown .dropdown-menu .dropdown-item:has(+ .dropdown-item:hover),
.filterDrop .dropdown-menu .dropdown-item:has(+ .dropdown-item:hover) {
  border-color: transparent !important;
}

.mainHeader .dropdown .dropdown-menu .dropdown-item:last-child,
.filterDrop .dropdown-menu .dropdown-item:last-child {
  border: none !important;
}

.headerSearch .form-control {
  min-width: 350px;
}

.btn-close,
.btn-close:focus-visible {
  font-size: 12px;
  opacity: 1;
  box-shadow: none !important;
  border: none !important;
  background-color: #0aa9dd2b;
  border-radius: 50px;
  padding: 10px !important;
}

.owl-nav .owl-prev,
.owl-nav .owl-next {
  width: 36px;
  height: 36px;
  border-radius: 50px;
  background-color: #0000003d;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  transition: ease-in-out 0.3s;
}

.owl-nav .owl-next {
  right: 0;
}

.owl-nav .owl-prev:hover,
.owl-nav .owl-next:hover {
  background-color: #0aa9dd;
}

.owl-dots {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.owl-dots .owl-dot {
  width: 16px;
  height: 16px;
  border-radius: 50px;
  border: 2px solid #0aa9dd;
  background-color: #fff;
  transition: ease-in-out 0.3s;
}

.owl-dots .owl-dot.active {
  width: 48px;
  background-color: #0aa9dd;
}

.heroSliderMain {
  -webkit-mask-image: url(../img/heroBg.svg);
  -webkit-mask-image: url(../img/heroBg.svg);
  -webkit-mask-position: center bottom;
  -webkit-mask-size: cover;
  -webkit-mask-repeat: no-repeat;
  display: block;
  position: relative;
  overflow: hidden;
}

/* .heroSliderMain .sliderImg::before {
  display: block;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.55);
  content: "";
} */

.sliderContent {
  max-width: 700px;
}

.sliderContent .mainTitle {
  font-size: 48px;
  line-height: 1.3;
  font-weight: bold;
}

.title .titleDesign::before,
.title .titleDesign::after {
  content: "";
  display: block;
  height: 5px;
  border-radius: 50px;
  background-color: #0aa9dd;
}

.title .titleDesign::after {
  background-color: #bfefff;
}

.mainTitle.title .titleDesign::before {
  width: 80px !important;
}

.mainTitle.title .titleDesign::after {
  width: 40px !important;
}

.heroSliderMain .owl-nav .owl-prev,
.heroSliderMain .owl-nav .owl-next,
.serviceDetailOuter .owl-nav .owl-prev,
.serviceDetailOuter .owl-nav .owl-next {
  width: 50px;
  height: 50px;
}

.heroSliderMain .owl-nav .owl-prev,
.serviceDetailOuter .owl-nav .owl-prev {
  left: -60px;
}

.heroSliderMain .owl-nav .owl-next,
.serviceDetailOuter .owl-nav .owl-next {
  right: -60px;
}

.heroSliderMain:hover .owl-nav .owl-prev,
.serviceDetailOuter:hover .owl-nav .owl-prev {
  left: 30px;
}

.heroSliderMain:hover .owl-nav .owl-next,
.serviceDetailOuter:hover .owl-nav .owl-next {
  right: 30px;
}

.heroSliderMain .owl-dots {
  bottom: 40px;
}

.btnStyle1,
.btnLight,
.btnStyle1.dangerBtn {
  font-size: 18px;
  font-weight: 500;
  padding: 8px 18px;
  white-space: nowrap;
  transition: ease-in-out 0.3s;
}

.btnStyle1.btnOutline {
  border: 2px solid #0aa9dd !important;
  color: #0aa9dd !important;
  background-color: transparent;
}

.btnStyle1.outLineDarkBtn {
  border: 1px solid #121212 !important;
  color: #121212 !important;
  background-color: transparent;
}

.btnStyle1.outLineThemeBtn {
  border: 1px solid #0aa9dd !important;
  color: #0aa9dd !important;
  background-color: transparent;
}

.btnStyle1.outLineDarkBtn.addFavBtn img {
  filter: brightness(0);
  transition: ease-in-out 0.3s;
}

.btn img {
  transition: ease-in-out 0.3s;
}

.btnStyle1.iconBtn {
  width: 40px;
  height: 40px;
}

.btnStyle1.btnOutline:hover,
.btnStyle1.btnOutline:active,
.btnStyle1.btnOutline:focus-visible {
  border-color: #0995c3 !important;
  color: #fff !important;
  background-color: #0995c3 !important;
}

.btnStyle1.outLineDarkBtn:hover,
.btnStyle1.outLineDarkBtn:active,
.btnStyle1.outLineDarkBtn:focus-visible,
.btnStyle1.outLineDarkBtn.addFavBtn.fav,
.btnStyle1.outLineDarkBtn.addFavBtn.fav,
.btnStyle1.outLineDarkBtn.addFavBtn.fav {
  border-color: #121212 !important;
  color: #fff !important;
  background-color: #121212 !important;
}

.btnStyle1.outLineDarkBtn.addFavBtn svg path {
  transition: ease-in-out 0.3s;
}

.btnStyle1.outLineDarkBtn.addFavBtn:hover svg path,
.btnStyle1.outLineDarkBtn.addFavBtn:active svg path,
.btnStyle1.outLineDarkBtn.addFavBtn:focus-visible svg path {
  stroke: #fff;
}

.btnStyle1.outLineDarkBtn.addFavBtn.fav svg path,
.btnStyle1.outLineDarkBtn.addFavBtn.fav svg path,
.btnStyle1.outLineDarkBtn.addFavBtn.fav svg path {
  stroke: #d0131b;
  fill: #d0131b;
}

.btnStyle1.outLineThemeBtn:hover,
.btnStyle1.outLineThemeBtn:active,
.btnStyle1.outLineThemeBtn:focus-visible {
  border-color: #0aa9dd !important;
  color: #fff !important;
  background-color: #0aa9dd !important;
}

.btnStyle1.outLineDarkBtn.addFavBtn:active img,
.btnStyle1.outLineDarkBtn.addFavBtn:focus-visible img,
.btnStyle1.outLineThemeBtn:hover img,
.btnStyle1.outLineThemeBtn:active img,
.btnStyle1.outLineThemeBtn:focus-visible img {
  filter: brightness(100);
}

/* .btnStyle1.outLineDarkBtn.addFavBtn:hover img, .btnStyle1.outLineDarkBtn.addFavBtn.fav img, */
.btnStyle1 {
  background-color: #0aa9dd;
  border-color: #0aa9dd !important;
}

.btnStyle1.dangerBtn {
  background-color: #d0131b;
  border-color: #d0131b !important;
}

.btnStyle1.dangerBtn:hover,
.btnStyle1.dangerBtn:active,
.btnStyle1.dangerBtn:focus-visible {
  background-color: #af1119 !important;
  border-color: #af1119 !important;
}

.btnStyle1:hover,
.btnStyle1:active,
.btnStyle1:focus-visible {
  background-color: #0995c3 !important;
  border-color: #0995c3 !important;
}

.btnLight {
  color: #0aa9dd;
  background-color: #fff;
}

.btnLight:hover,
.btnLight:active,
.btnLight:focus-visible {
  color: #0aa9dd !important;
  background-color: rgb(202, 202, 202) !important;
}

.sectionHeading .title {
  font-family: "Barlow";
  font-size: 22px;
  font-weight: 600;
  line-height: 1;
}

.sectionHeading .title .titleDesign::before,
.title .titleDesign::after {
  width: 70% !important;
}

.sectionHeading .title .titleDesign::after {
  width: 30% !important;
}

.textBtn {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.25;
  color: #0aa9dd;
}

.sectionPading {
  padding: 24px 0;
}

.categorySection .owl-nav .owl-prev,
.categorySection .owl-nav .owl-next {
  top: 32px;
  bottom: auto;
}

.categoriesItem .categoriesImg {
  width: 120px;
  height: 120px;
  border: 2px solid transparent;
  transition: ease-in-out 0.3s;
  box-shadow: 0 0 7px #00000000;
  margin: 5px;
}

.categoriesItem:hover .categoriesImg {
  border-color: #0aa9dd;
}

.categoriesItem .categoriesImg img {
  object-fit: cover;
  object-position: top center;
  transition: ease-in-out 0.3s;
}

.categoriesItem .categoriesName,
.dealerName {
  font-size: 15px;
  font-weight: 600;
  color: #121212;
  line-height: 1.25;
  transition: ease-in-out 0.3s;
}

.categoriesItem:hover .categoriesImg img {
  transform: scale(1.1);
}

.categoriesItem:hover .categoriesName,
.categoriesItem:hover .dealerName {
  color: #0aa9dd;
}

.categoriesItem .dealerName {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.brandsOuter .brandsItem {
  width: 100%;
  height: 100px;
  padding: 16px;
  overflow: hidden;
  position: relative;
  border-color: #bfefff !important;
  transition: ease-in-out 0.3s;
}

.brandsOuter .brandsItem img {
  transition: ease-in-out 0.3s;
}

.brandsOuter .brandsItem:hover {
  border-color: #0aa9dd !important;
}

.brandsOuter .brandsItem:hover img {
  transform: scale(1.1);
}

.bgLight {
  background-color: #0aa9dd1c;
}

.productItem {
  padding: 12px;
  border-radius: 12px;
}

.productItem .productImg {
  height: 160px;
  border-radius: 12px 12px 0 0;
  overflow: hidden;
  background-color: #f6f9fa;
}

.productItem .productImg img {
  object-fit: contain;
  transition: ease-in-out 0.3s;
}

.productItem:hover .productImg img {
  transform: scale(1.1);
}

.favbtn {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.favbtn.favouriteItem svg path {
  fill: #ff0000;
  stroke: #ff0000;
}

.productItem .productDetail {
  padding-top: 12px;
}

.productItem .proTitle {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.25;
  color: #121212;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.productItem .proRating {
  font-size: 15px;
  font-weight: 600;
  line-height: 1.25;
  color: #121212;
}

.productItem .proRating img {
  width: 16px;
}

.productItem .brandName {
  font-size: 15px;
  font-weight: 600;
  line-height: 1.25;
  color: #5b5b5b;
}

.productItem .price {
  font-size: 18px;
  font-weight: 700;
  color: #121212;
  line-height: 1.25;
}

.productItem .btnStyle1 {
  font-size: 14px;
  padding: 10px;
  line-height: 16px;
}

.specialistSection {
}

.specialistSection .specialistItemOuter {
  gap: 12px;
}

.specialistItem {
  padding: 16px;
  border-radius: 12px;
}

.specialistItem .imgOuter {
  width: 60px;
  height: 60px;
  border: 1px solid #a5a5a5;
}

.specialistItem .imgOuter img {
  object-fit: cover;
  object-position: top center;
}

.specialistItem .specialistDetail .name {
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
  color: #121212;
}

.workType li {
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  color: #5b5b5b;
  position: relative;
}

.workType li::after {
  content: "";
  display: block;
  width: 6px;
  height: 6px;
  border-radius: 50px;
  background-color: #a5a5a5;
  position: absolute;
  top: 0;
  bottom: 0;
  right: -3px;
  margin: auto 0;
}

.workType li:first-child {
  padding-left: 0 !important;
}

.workType li:last-child {
  padding-right: 0 !important;
}

.workType li:last-child:after {
  display: none;
}

.specialistItem .specialistDetail .dec {
  font-size: 15px;
  line-height: normal;
  color: #a5a5a5;
}

.footerMain .bottomFooter {
  background-color: #0aa9dd;
  padding: 12px 0;
}

.footerMain .bottomFooter .footerNav .navItem .navLink,
.bottomFooter .copyRight {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.25;
  white-space: nowrap;
}

.modal .modal-content {
  background-color: #f6f9fa;
}

.form-control,
.form-select,
.input-group .react-tel-input .form-control {
  border: none;
  box-shadow: none !important;
  background-color: #fff;
  padding: 9px 16px;
  font-size: 15px;
  color: #121212;
  font-weight: 500;
}

.react-tel-input {
  display: flex;
  align-items: center;
}

.react-tel-input .form-control {
  width: 100% !important;
  order: 2;
  border-radius: 0 5px 5px 0 !important;
}

.react-tel-input .flag-dropdown {
  position: unset !important;
  order: 1;
  height: 100%;
  border: none !important;
  background: #fff !important;
  border-radius: 5px 0 0 5px !important;
  border-right: 1px solid #ccc !important;
  padding-right: 15px !important;
}

.form-control::placeholder {
  color: #a5a5a5;
}

.form-label {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 4px;
  color: #121212;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.loginSignUpForm .form-select {
  max-width: 100px;
}

.form-select {
  background-size: 22px 18px;
}

.loginSignUpForm .vr {
  width: 1px;
  height: 22px;
  margin: auto 0;
}

.passBtn {
  top: 12px;
  right: 12px;
}

.loginSignUpForm .form-check-input {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  box-shadow: none;
  margin: 0;
  transition: ease-in-out 0.3s;
}

.loginSignUpForm .form-check-input:focus {
  border-color: #0aa8dd4f;
}

.loginSignUpForm .form-check-input:checked {
  background-color: #0aa9dd;
  border-color: #0aa9dd;
}

.loginSignUpForm .form-check-label {
  font-size: 16px;
  color: #121212;
  text-decoration: underline;
}

.otherLink {
  font-size: 16px;
  color: #121212;
}

.textBtn {
  font-size: 16px;
  color: #0aa9dd;
  font-weight: normal;
  text-decoration: underline;
  transition: ease-in-out 0.3s;
}

.textBtn.textBtnDark,
.textBtn.textBtnDark:hover,
.textBtn.textBtnDark:focus-visible {
  color: #121212;
}

.textBtn:hover,
.textBtn:focus-visible {
  color: #0081ab;
}

.otpModal .form-control {
  width: 60px;
  height: 60px;
  border-bottom: 2px solid #a5a5a5;
  border-radius: 0;
  transition: ease-in-out 0.3s;
}

.otpModal .form-control:focus,
.otpModal .form-control.valid {
  border-color: #0aa9dd;
}

.vehicleImgUploadLabel {
  border-radius: 12px;
  cursor: pointer;
  border: 1px dashed #0aa9dd;
}

.categoryWraper .categoriesItem {
  padding: 16px;
  border-radius: 12px;
  background-color: #fff;
}

.categoriesItem .totalItem {
  font-size: 14px;
  font-weight: 400;
  color: #5b5b5b;
}

.searchBtn {
  width: 32px;
  height: 32px;
  z-index: 6 !important;
  padding: 6px;
  background-color: #0aa9dd;
  transition: ease-in-out 0.3s;
}

.searchBtn img {
  filter: brightness(1000);
}

.searchBtn:hover,
.searchBtn:active {
  background-color: #0aa9dd !important;
}

.otherSliderMain {
  padding: 10px 0;
}

.otherSliderMain .owl-dots {
  bottom: -30px;
}

.partItem {
  border-radius: 12px;
}

.partItem .imgOuter {
  height: 200px;
}

.partItem .imgOuter img {
  object-fit: cover;
  object-position: top center;
}

.partItem .contentPart {
  padding: 12px;
  background: linear-gradient(
    360deg,
    rgba(18, 18, 18, 0.9) 34.43%,
    rgba(18, 18, 18, 0) 100%
  );
}

.partItem .contentPart .partTitle {
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  line-height: 1.25;
}

.partItem .contentPart .partPrice {
  font-size: 20px;
  font-weight: 600;
  color: #fff;
  line-height: 1.3;
}

.searchOffcanvas.offcanvas-lg.offcanvas-top {
  height: 96px;
  background-color: #f6f9fa;
}

.vehicleItem {
  border-radius: 12px;
}

.vehicleItem .imgOuter {
  width: 120px;
  height: 120px;
  background-color: #f6f9fa;
  border-radius: 8px;
  overflow: hidden;
}

.vehicleItem .imgOuter img {
  object-fit: contain;
  transition: ease-in-out 0.3s;
}

.vehicleItem:hover .imgOuter img {
  transform: scale(1.1);
}

.vehicleItem .vehicleDetail .brand,
.vehicleItem .vehicleDetail .name,
.vehicleItem .vehicleDetail .year,
.vehicleItem .vehicleDetail .vehicleNumber {
  font-size: 14px;
  font-weight: 600;
  color: #121212;
  line-height: 1.3;
}

.vehicleItem .vehicleDetail .name {
  font-size: 16px;
  margin: 3px 0;
}

.vehicleItem .vehicleDetail .year {
  font-size: 14px;
  color: #5b5b5b;
}

.vehicleItem .vehicleDetail .vehicleNumber {
  font-size: 20px;
  margin-top: 3px;
}

.editBtn,
.deleteBtn {
  width: 36px;
  height: 36px;
  background-color: #f6f9fa;
  transition: ease-in-out 0.3s;
}

.deleteBtn {
  background-color: #d0131b1c;
}

.deleteBtn:hover,
.deleteBtn:active {
  background-color: #d0131b;
}

.editBtn img,
.deleteBtn img {
  transition: ease-in-out 0.3s;
}

.editBtn:hover,
.editBtn:active {
  background-color: #0aa9dd;
}

.editBtn:hover img,
.editBtn:active img,
.deleteBtn:hover img,
.deleteBtn:active img {
  filter: brightness(100);
}

.serviceDetailOuter .serviceImage {
  height: 700px;
  overflow: hidden;
}

.serviceDetailOuter .serviceImage img {
  object-fit: contain;
}

.serviceDetailOuter .serviceDec {
  margin-top: -40px;
  position: relative;
  z-index: 111;
}

.serviceDetailOuter .serviceDec .name {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.25;
  color: #121212;
}

.serviceDetailOuter .serviceDec .serviceCharge {
  font-size: 22px;
  font-weight: 700;
  line-height: 1.25;
  color: #121212;
}

.serviceDetailOuter .favbtn {
  width: 24px;
  height: 24px;
}

.specialistWraper .specialistItem .imgOuter,
.dealerWraper .specialistItem .imgOuter {
  width: 100px;
  height: 100px;
}

.specialistWraper .specialistItem .name {
  font-size: 16px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.specialistItem .serviceCharge {
  font-size: 20px;
  font-weight: 700;
  color: #121212;
  line-height: 1.25;
}

.specialistItem .btnStyle1 {
  font-size: 14px;
  padding: 10px;
  line-height: 16px;
}

.productsDetailOuter .productImgOuter {
  background-color: #fff;
}

.productsDetailOuter .productImg {
  height: 400px;
}

.productsDetailOuter .productImg img {
  object-fit: contain;
}

.productsDetailOuter .owl-nav .owl-prev {
  left: 16px;
}

.productsDetailOuter .owl-nav .owl-next {
  right: 16px;
}

.productFullDetail .name {
  font-size: 18px;
  font-weight: 600;
  color: #121212;
  line-height: 1.5;
}

.productFullDetail .brand {
  font-size: 15px;
  font-weight: 600;
  color: #999999;
}

.productFullDetail .category {
  font-size: 15px;
  font-weight: 500;
  color: #5b5b5b;
}

.productFullDetail .proRating {
  font-size: 16px;
  font-weight: 600;
}

.productFullDetail img {
  width: 18px;
}

.productFullDetail .proPrice {
  font-size: 24px;
  font-weight: 700;
  color: #121212;
}

.proDec p {
  font-size: 16px;
  color: #585858;
  line-height: 1.6;
  margin-bottom: 8px;
}

.proDec .textBtn {
  background-color: #f6f9fa;
}

.filterDrop .dropdown-toggle::after {
  display: none;
}

.filterDrop .dropdown-toggle.show {
  background-color: #0000003d;
}

.filterDrop .dropdown-menu {
  min-width: 180px;
}

.specialistProfileOuter .proCoverImg {
  height: 320px;
  /* border-radius: 0 0 40px 40px; */
}

.specialistProfileOuter .proCoverImg img {
  object-fit: cover;
}

.profileDetail {
}

.profileDetail .proImgOuter {
  width: 150px;
  height: 150px;
  margin-top: -50px;
  flex: 0 0 auto;
}

.profileDetail .proImgOuter .bgDesign {
  left: -33px;
}

/* .profileDetail .proImgOuter::after {
    content: '';
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 0 0 0 39px;
    border-left: 9px solid #fff;
    border-bottom: 8px solid #fff;
    position: absolute;
    bottom: 67px;
    right: -25px;
}
.profileDetail .proImgOuter::before {
    content: '';
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 0 0 39px 0;
    border-right: 9px solid #F6F9FA;
    border-bottom: 8px solid #F6F9FA;
    position: absolute;
    bottom: 67px;
    left: -25px;
} */
.profileDetail .proImg {
  border: 10px solid #f6f9fa;
  z-index: 2;
}

.profileDetail .proImg img {
  object-fit: cover;
  object-position: top center;
}

.profileDetail .detail .name {
  font-size: 20px;
  font-weight: 600;
  color: #121212;
}

.typeItem {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.25;
  height: 60px;
  color: #121212;
  border: 1px solid #e7e7e7;
  transition: ease-in-out 0.3s;
}

/* .typeItem:hover{
    background-color: #0aa9dd14 !important;
    color: #0AA9DD;
    border-color:#0AA9DD;
} */
.productFooter .usedPro {
  font-size: 16px;
  font-weight: 500;
  color: #121212;
}

.productFooter .usedPro::before {
  content: "";
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 50px;
  background-color: #fdc604;
}

.seiviceCatalogueItem {
  border-radius: 12px;
  border: 1px solid #f1f1f1;
}

.seiviceCatalogueItem .serviceImg {
  width: 150px;
  height: 100px;
  flex: 0 0 auto;
}

.seiviceCatalogueItem .serviceImg img {
  object-fit: cover;
  object-position: top center;
}

.seiviceCatalogueItem .serviceDetail .serviceTitle {
  font-size: 17px;
  font-weight: 600;
  color: #121212;
}

.seiviceCatalogueItem .serviceDetail .servicePrice {
  font-size: 22px;
  font-weight: 700;
  color: #121212;
}

.favTabs .nav-tabs {
  background-color: #fff;
  position: sticky;
  top: 0;
  z-index: 100;
}

.favTabs .nav-link {
  border: none;
  border-bottom: 3px solid transparent;
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  color: #121212;
  background: #fff;
  border-radius: 0;
  padding: 10px 30px;
  transition: ease-in-out 0.3s;
  width: 100%;
}

.favTabs .nav-link:hover {
  color: #0aa9dd;
  background-color: #0aa9dd14;
  border-color: transparent;
}

.favTabs .nav-link.active {
  color: #0aa9dd;
  background-color: #0aa9dd14;
  border-color: #0aa9dd;
}

.blogItem {
  border-radius: 12px;
  overflow: hidden;
}

.blogItem .blogDetail {
  padding: 12px;
}

.blogItem .blogImg {
  width: 100%;
  height: 175px;
  overflow: hidden;
  border-radius: 12px;
}

.blogItem .blogImg img {
  object-fit: cover;
  object-position: top center;
  transition: ease-in-out 0.3s;
}

.blogItem:hover .blogImg img {
  transform: scale(1.1);
}

.blogDate {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.3;
  color: #0aa9dd;
}

.blogItem .blogDetail .blogTitle {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.5;
  color: #121212;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.blogItem .blogDetail .arrow svg {
  transform: rotate(-45deg);
  transition: ease-in-out 0.3s;
}

.blogItem .blogDetail .arrow svg path {
  transition: ease-in-out 0.3s;
}

.blogItem:hover .blogDetail .arrow svg path,
.blogItem:hover .blogDetail .arrow svg {
  transform: none;
  stroke: #0aa9dd;
}

.blogItem .blogDetail .dec {
  font-size: 15px;
  font-weight: 400;
  line-height: 1.25;
  color: #5b5b5b;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.blogOuter .blogImgOuter {
  width: 100%;
  height: 500px;
  border-radius: 12px;
  overflow: hidden;
}

.blogOuter .blogImgOuter img {
  object-fit: cover;
  object-position: top center;
}

.blogFullDetail {
  padding-top: 16px;
}

.blogOuter .blogTitle {
  font-size: 22px;
  font-weight: 700;
  line-height: 1.5;
  color: #121212;
}

.blogOuter .pera {
  font-size: 16px;
  line-height: 1.6;
  color: #474747;
}

.buttonGroup {
  z-index: 101;
}

.manageQuotes .favTabs .nav-tabs {
  border: none;
}

.quoteItem {
  border-radius: 12px;
  border: 1px solid #fff;
  transition: ease-in-out 0.3s;
}

.quoteItem .quoteType {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.25;
  color: #0aa9dd;
}

.quoteItem .quoteTitle {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.25;
  color: #5b5b5b;
}

.quoteItem .quoteValue {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.25;
  color: #121212;
  margin-bottom: 4px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  overflow: hidden;
  -webkit-box-orient: vertical;
}

.quoteItem .quoteDec {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.5;
  color: #121212;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.receiveQuoteOuter .quoteItem:hover {
  border-color: #0aa9dd;
}

.quoteDealerPro .imgOuter {
  width: 70px;
  height: 70px;
}

.quoteDealerPro .imgOuter img {
  object-fit: cover;
  object-position: top center;
}

.quoteDealerPro .name {
  font-size: 16px;
  font-weight: 600;
  color: #121212;
  line-height: 1.5;
}

.quoteDealerPro .dec {
  font-size: 14px;
  font-weight: 500;
  color: #5b5b5b;
  line-height: 1.25;
}

.quotesDetail .quoteItem .quoteDec {
  -webkit-line-clamp: unset;
}

.vehicleOuter {
  border-radius: 12px;
  padding: 12px;
  border: 2px solid #fff;
  transition: ease-in-out 0.3s;
  cursor: pointer;
  text-align: center;
  width: 190px;
}

.vehicleOuter:hover {
  border-color: #0aa8dd48;
}

.vehicleOuter .brandName {
  font-size: 16px;
  font-weight: 600;
  color: #121212;
  line-height: 1.3;
}

.vehicleOuter .vehicleModal {
  font-size: 14px;
  font-weight: 500;
  color: #5b5b5b;
  line-height: 1.3;
}

.vehicleOuter .vehiclenumber {
  font-size: 14px;
  font-weight: 500;
  color: #121212;
  line-height: 1.3;
}

.vehicleOuter .vehicleImg {
  width: 100%;
  height: 80px;
  overflow: hidden;
}

.vehicleOuter .vehicleImg img {
  height: 100%;
}

.selectVehicleCheck {
  padding: 0;
  margin: 0;
  position: relative;
}

.selectVehicleCheck .form-check-input {
  background-image: none;
  width: 24px;
  height: 24px;
  box-shadow: none;
  border-color: #0aa9dd;
  background-color: #0aa9dd;
  position: absolute;
  margin: 0;
  float: unset;
  top: 0;
  right: 0;
  opacity: 0;
  transition: ease-in-out 0.3s;
}

.selectVehicleCheck .form-check-input::after {
  content: "";
  display: block;
  width: 6px;
  height: 11px;
  border-bottom: 2px solid #fff;
  border-right: 2px solid #fff;
  position: absolute;
  top: 4px;
  left: 0;
  right: 0;
  margin: auto;
  transform: rotate(45deg);
}

.selectVehicleCheck .form-check-input:checked {
  opacity: 1;
}

.selectVehicleCheck
  .form-check-input:checked
  ~ .form-check-label
  .vehicleOuter {
  border-color: #0aa9dd !important;
}

.selectVehicleOuter::-webkit-scrollbar {
  display: none;
  opacity: 0;
}

.selectVehicleOuter {
  position: relative;
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  transition: all 0.2s;
  /* transform: scale(0.98); */
  will-change: transform;
  user-select: none;
  cursor: pointer;
}

.chatMainOuter {
  height: calc(100vh - 146px);
  min-height: 380px;
  box-shadow: 0 0 4px rgb(0 0 0 / 15%);
  border-radius: 12px;
  overflow: hidden;
  transform: translate(0, 0);
}

.chatMainOuter .chatMainInner .conatctSide {
  box-shadow: 0px 0px 4px 0px #00000026;
}

.chatMainOuter .chatMainInner .conatctSide .contactSearch {
  background-color: #0aa9dd;
}

.chatMainOuter .chatMainInner .conatctSide .contactList .contactItem {
  transition: ease-in-out 0.3s;
  cursor: pointer;
}

.chatMainOuter
  .chatMainInner
  .conatctSide
  .contactList
  .contactItem
  .profileOuter {
  width: 46px;
  height: 46px;
}

.chatMainOuter .chatMainInner .conatctSide .contactList .contactItem.active,
.chatMainOuter
  .chatMainInner
  .conatctSide
  .contactList
  .contactItem.active:hover {
  background-color: #0aa9dd3b;
}

.chatMainOuter .chatMainInner .conatctSide .contactList .contactItem:hover {
  background-color: #0aa9dd17;
}

.chatMainOuter
  .chatMainInner
  .conatctSide
  .contactList
  .contactItem.newMessage
  .message
  .newMsg {
  display: flex !important;
}

.chatMainOuter
  .chatMainInner
  .conatctSide
  .contactList
  .contactItem:first-child {
  border-color: transparent !important;
}

.chatMainOuter
  .chatMainInner
  .conatctSide
  .contactList
  .contactItem
  .profileOuter
  img {
  object-fit: cover;
  object-position: top center;
}

.chatMainOuter
  .chatMainInner
  .conatctSide
  .contactList
  .contactItem
  .title
  .name,
.chatMainOuter
  .chatMainInner
  .chatingSide
  .chatHeader
  .clientPro
  .detail
  .name {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.3;
  color: #121212;
}

.chatMainOuter
  .chatMainInner
  .chatingSide
  .chatHeader
  .clientPro
  .detail
  .name {
  font-size: 15px;
  color: #fff;
}

.chatMainOuter
  .chatMainInner
  .conatctSide
  .contactList
  .contactItem
  .title
  .seenTime {
  font-size: 13px;
  font-weight: 500;
  line-height: normal;
  color: #121212;
}

.chatMainOuter
  .chatMainInner
  .conatctSide
  .contactList
  .contactItem
  .message
  .lastMsg {
  font-size: 13px;
  font-weight: 500;
  line-height: 19px;
  color: #5b5b5b;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  word-break: break-all;
}

.chatMainOuter
  .chatMainInner
  .conatctSide
  .contactList
  .contactItem
  .message
  .newMsg {
  flex: 0 0 20px;
  width: 20px;
  height: 20px;
  background-color: #09c939;
  font-size: 14px;
}

.chatMainOuter .chatMainInner .chatingSide .chatHeader,
.chatMainOuter .chatMainInner .chatingSide .chatFooter {
  background-color: #0aa9dd;
}

.chatMainOuter .chatMainInner .chatingSide .chatHeader .clientPro .imgOuter {
  width: 40px;
  height: 40px;
}

.chatMainOuter
  .chatMainInner
  .chatingSide
  .chatHeader
  .clientPro
  .imgOuter
  img {
  object-fit: cover;
  object-position: top center;
}

.chatMainOuter
  .chatMainInner
  .chatingSide
  .chatHeader
  .clientPro
  .detail
  .status {
  font-size: 12px;
  font-weight: 400;
  color: #fff;
}

.chatMainOuter
  .chatMainInner
  .chatingSide
  .chatHeader
  .clientPro
  .detail
  .status
  .online {
  color: #fff;
}

.chatMainOuter
  .chatMainInner
  .chatingSide
  .chatHeader.online
  .clientPro
  .detail
  .status
  .online {
  display: flex !important;
}

.chatMainOuter
  .chatMainInner
  .chatingSide
  .chatHeader.online
  .clientPro
  .detail
  .status
  .lastSeen {
  display: none;
}

.chatMainOuter .chatMainInner .chatingSide .chatOuter .msgDate .date {
  white-space: nowrap;
  font-size: 14px;
  color: #121212;
  background-color: #fff;
  border-radius: 4px;
  padding: 4px 12px;
}

.chatMainOuter .chatMainInner .chatingSide .chatOuter .msgMain .imgOuter {
  width: 36px;
  height: 36px;
}

.chatMainOuter .chatMainInner .chatingSide .chatOuter .msgMain .imgOuter img {
  object-fit: cover;
  object-position: top center;
}

.chatMainOuter .chatMainInner .chatingSide .chatOuter .msgMain .msg {
  border-radius: 16px;
  /* border: 1px solid #C8C8C8; */
}

.chatMainOuter .chatMainInner .chatingSide .chatOuter .msgMain .msg:last-child {
  margin-bottom: 16px !important;
}

.chatMainOuter .chatMainInner .chatingSide .chatOuter .msgMain .msg.txtMsg {
  border-radius: 0 16px 16px;
  max-width: 80%;
  padding: 10px;
  width: fit-content;
  background-color: #fff;
}

.chatMainOuter
  .chatMainInner
  .chatingSide
  .chatOuter
  .msgMain
  .msg.txtMsg
  .txt {
  display: contents;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  color: #121212;
  word-break: break-all;
}

.chatMainOuter .chatMainInner .chatingSide .chatOuter .msgMain .msg.docMsg {
  border: none;
  max-width: 280px;
  border-radius: 0;
  border-radius: 16px;
  position: relative;
  padding: 10px 10px 20px 10px;
  background-color: #fff;
}

.chatMainOuter
  .chatMainInner
  .chatingSide
  .chatOuter
  .msgMain
  .msg.docMsg
  .txt {
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  color: #121212;
  margin-top: 4px;
}

.chatMainOuter
  .chatMainInner
  .chatingSide
  .chatOuter
  .msgMain
  .msg.docMsg
  .document {
  background-color: #f6f6f6;
  border-radius: 6px;
  padding: 6px;
}

.chatMainOuter
  .chatMainInner
  .chatingSide
  .chatOuter
  .msgMain
  .msg.docMsg
  .documentName {
  font-size: 14px;
  font-weight: 600;
  color: #121212;
}

.chatMainOuter
  .chatMainInner
  .chatingSide
  .chatOuter
  .msgMain
  .msg.docMsg
  .docIcon {
  width: 24px;
  height: 24px;
}

.chatMainOuter
  .chatMainInner
  .chatingSide
  .chatOuter
  .msgMain
  .msg.docMsg
  .msgTime {
  position: absolute;
  bottom: 0;
  right: 0;
  padding-right: 10px;
  margin: 0 !important;
  width: 100%;
  text-align: end;
  color: #121212;
}

.chatMainOuter
  .chatMainInner
  .chatingSide
  .chatOuter
  .msgMain.send
  .msg.docMsg {
  background-color: #0aa9dd;
}

.chatMainOuter
  .chatMainInner
  .chatingSide
  .chatOuter
  .msgMain.send
  .msg.docMsg
  .docIcon
  svg
  path {
  fill: #fff;
}

.chatMainOuter
  .chatMainInner
  .chatingSide
  .chatOuter
  .msgMain.send
  .msg.docMsg
  .documentName,
.chatMainOuter
  .chatMainInner
  .chatingSide
  .chatOuter
  .msgMain.send
  .msg.docMsg
  .msgTime,
.chatMainOuter
  .chatMainInner
  .chatingSide
  .chatOuter
  .msgMain.send
  .msgCol
  .msg.txtMsg
  .txt,
.chatMainOuter
  .chatMainInner
  .chatingSide
  .chatOuter
  .msgMain.send
  .msg.docMsg
  .txt {
  color: #fff;
}

.chatMainOuter .chatMainInner .chatingSide .chatOuter .msgMain.send .imgCol {
  display: none !important;
}

.chatMainOuter
  .chatMainInner
  .chatingSide
  .chatOuter
  .msgMain.send
  .msgCol
  .msg {
  margin-left: auto;
  border-color: #0aa9dd;
}

.chatMainOuter
  .chatMainInner
  .chatingSide
  .chatOuter
  .msgMain.send
  .msg.docMsg
  .document {
  background-color: #ffffff17;
}

.chatMainOuter
  .chatMainInner
  .chatingSide
  .chatOuter
  .msgMain.send
  .msgCol
  .msg.txtMsg {
  background-color: #0aa9dd;
  border-radius: 16px 0 16px 16px;
  color: #fff;
}

.chatMainOuter .chatMainInner .chatingSide .chatOuter .msgMain .msgTime {
  font-size: 10px;
  font-weight: 500;
}

.chatMainOuter .chatMainInner .chatingSide .chatFooter .footerBtnGroup {
  top: 0;
  bottom: 0;
  margin: auto;
  right: 4px;
}

.chatMainOuter
  .chatMainInner
  .chatingSide
  .chatFooter
  .footerBtnGroup
  .fileAtechLabel {
  width: 32px;
  height: 32px;
  cursor: pointer;
}

.chatMainOuter
  .chatMainInner
  .chatingSide
  .chatFooter
  .footerBtnGroup
  .btnMsgSend {
  width: 32px;
  height: 32px;
  padding: 8px;
  transition: ease-in-out 0.3s;
  background-color: #0aa9dd;
  z-index: 6;
}

.chatMainOuter
  .chatMainInner
  .chatingSide
  .chatFooter
  .footerBtnGroup
  .btnMsgSend
  img,
.backBtn img {
  filter: brightness(100);
}

.notificationItem {
  border-radius: 12px;
  border: 1px solid #00000014;
}

.notificationItem .imgOuter {
  width: 50px;
  height: 50px;
}

.notificationItem .imgOuter img {
  object-fit: cover;
  object-position: top center;
}

.notificationItem .title {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  color: #121212;
}

.notificationItem .notiTime {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #8f8f8f;
  white-space: nowrap;
}

.notificationItem .notificationDec {
  font-size: 15px;
  font-weight: 500;
  color: #121212;
}

.notificationItem .notificationDec:not(.show) {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.notificationItem .notificationReadMore {
  top: 45px;
}

.notificationItem
  .notificationReadMore:has(
    + .notificationDec.show,
    + .notificationDec.collapsing
  )
  img {
  transform: rotate(180deg);
}

.notificationItem .deleteBtn {
  width: 30px;
  height: 30px;
  padding: 6px;
  flex: 0 0 auto;
}

.notificationItem::before {
  content: "";
  display: block;
  width: 0;
  height: 10px;
  border-radius: 50px;
  background-color: #0aa9dd;
  transition: ease-in-out 0.3s;
}

.notificationItem.unread {
  border-color: #0aa9dd !important;
}

.notificationItem.unread::before {
  width: 10px;
}

.headerResultOuter .headerResultMenu {
  border-color: #e7e7e7 !important;
  transition: ease-in-out 0.3s;
}

.headerResultOuter .headerResultMenu:hover {
  color: #0aa9dd !important;
  letter-spacing: 0.2px;
}

.headerResultOuter .headerResultMenu:last-child {
  border: none !important;
}

.profileMainWrapper .profileMainOuter .imgOuter,
.profileMainWrapper .editProfileMainOuter .profileChange {
  width: 100px;
  height: 100px;
}

.profileMainWrapper .profileMainOuter .imgOuter img,
.profileMainWrapper .editProfileMainOuter .imgOuter img {
  object-fit: cover;
  object-position: top center;
}

.profileMainWrapper .profileMainOuter .prodetail,
.editProfileMainOuter .form-control,
.editProfileMainOuter .form-select {
  background: #f6f9fa;
}

.editProfileMainOuter .form-select {
  max-width: 50px;
}

.profileMainWrapper .profileMainOuter .prodetail .title {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.5;
  color: #797c7b;
}

.profileMainWrapper .profileMainOuter .prodetail .value {
  font-size: 17px;
  font-weight: 600;
  line-height: 1.5;
  color: #121212;
}

.uploadProfile {
  width: 30px;
  height: 30px;
  border-radius: 50px;
  background-color: #121212;
  padding: 6px;
  position: absolute;
  bottom: 0;
  right: 0;
}

.uploadProfile img {
  filter: brightness(100);
}

.pageImgOuter {
  height: 360px;
  position: relative;
}

.pageImgOuter img {
  object-fit: cover;
  object-position: top center;
}

.pageImgOuter::before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  inset: 0;
  margin: auto;
}

.pageTitle {
  inset: 0;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 80px;
  font-weight: bold;
  opacity: 0.3;
}

.contentWrapper .pera {
  font-size: 16px;
  color: #6c6c6c;
  font-weight: 500;
  line-height: 1.5;
}

.productAttribute .form-check {
  padding: 0;
  margin: 0;
}

.productAttribute .form-check .form-check-label .colour {
  width: 40px;
  height: 40px;
  border-radius: 50px;
  flex: 0 0 auto;
  position: relative;
  cursor: pointer;
}

.productAttribute .form-check .form-check-label .colour::before {
  content: "";
  display: block;
  width: 8px;
  height: 16px;
  border-bottom: 3px solid #fff;
  border-right: 3px solid #fff;
  position: absolute;
  top: -4px;
  bottom: 0;
  right: -1px;
  left: 0;
  margin: auto;
  transform: rotate(45deg);
  opacity: 0;
  transition: ease-in-out 0.3s;
}

.productAttribute .form-check .form-check-input {
  display: none;
}

.productAttribute
  .form-check
  .form-check-input:checked
  ~ .form-check-label
  .colour::before {
  opacity: 1;
}

.productAttribute .productDetail .title {
  font-size: 17px;
  font-weight: 600;
  color: #121212;
  line-height: 1.5;
}

.productAttribute .productDetail .value {
  font-size: 16px;
  font-weight: 500;
  color: #121212;
  line-height: 1.5;
}

.otherSliderMain .otherSliderItem {
  height: 250px;
}

.otherSliderMain .otherSliderItem img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top center;
}

.bottomBarMain {
  background-color: #0aa9dd;
  z-index: 1051;
  border-radius: 30px 30px 0 0;
  box-shadow: 0px 0px 16px #0000001f;
}

.bottomBarMain .nav-link {
  font-size: 14px;
  font-weight: 500;
  transition: ease-in-out 0.3s;
}

.bottomBarMain .nav-link::after {
  content: "";
  display: block;
  width: 0;
  height: 3px;
  background-color: #fff;
  border-radius: 50px;
  margin-top: 5px;
  transition: ease-in-out 0.3s;
}

.bottomBarMain .nav-link.active::after {
  width: 140%;
}

.active.categoriesItem .categoriesImg {
  border-color: #0aa9dd;
  box-shadow: 0 0 7px #0aa9dd;
}

.active.categoriesItem .categoriesName {
  color: #0aa9dd;
}

span.selectedCategory {
  color: #0aa9dd;
}

.active.categoriesItem .categoriesImg:after {
  content: "";
  position: absolute;
  left: auto;
  top: 8px;
  width: 6px;
  height: 12px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  right: 11px;
  z-index: 1;
}

.categoriesItem .categoriesImg img {
  overflow: hidden;
  border-radius: 50%;
}

.active.categoriesItem .categoriesImg:before {
  background: #0aa9dd;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  content: "";
  border-radius: 50%;
  position: absolute;
  right: 0;
  left: auto;
  z-index: 1;
}

.faqMainSection .accordion-header button {
  font-size: 18px;
  font-weight: 600;
  box-shadow: none;
  transition: ease-in-out 0.3s;
}

.faqMainSection .accordion-header button:not(.collapsed) {
  background-color: #0aa9dd;
  color: #fff;
}

.faqMainSection .accordion-header button.collapsed:hover {
  background-color: #e1f8ff;
  border-color: #e1f8ff !important;
}

.faqMainSection .accordion-button::before,
.faqMainSection .accordion-button::after {
  content: "";
  display: block;
  background-image: none;
  background-color: #0aa9dd;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  transition: ease-in-out 0.3s;
}

.faqMainSection .accordion-button:not(.collapsed)::before,
.faqMainSection .accordion-button:not(.collapsed)::after {
  background-color: #fff;
}

.faqMainSection .accordion-button::before {
  width: 18px;
  height: 3px;
  border-radius: 50px;
  right: 14px;
}

.faqMainSection .accordion-button:not(.collapsed):after {
  transform: rotate(90deg);
}

.faqMainSection .accordion-button::after {
  width: 3px;
  height: 18px;
  border-radius: 50px;
  right: 21px;
}

.faqMainSection .accordion-body .dec {
  font-size: 16px;
  line-height: 1.7;
  font-weight: 500;
}

.productAttribute .form-check .form-check-label .colour {
  border: solid 2px rgb(231 231 231);
}

.colour::before {
  border-color: rgb(170 234 255) !important;
}

.chatProductDetailOuter {
  max-width: 100%;
  width: 100%;
  border-radius: 0 0 12px 12px;
  z-index: 100;
  transition: ease-in-out 0.3s;
}

.chatProductDetailOuter.hide {
  top: -115.5px !important;
}

.chatProductDetailOuter .hideArrowBtn {
  width: 80px;
  height: 30px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 0 0 8px 8px;
  left: 0;
  right: 0;
  bottom: -30px;
  margin: auto;
}

.chatProductDetailOuter .hideArrowBtn img {
  transition: ease-in-out 0.3s;
}

.chatProductDetailOuter:not(.hide) .hideArrowBtn img {
  transform: rotateX(180deg);
}

.chatProductDetailOuter .title {
  font-size: 15px;
}

.chatProductDetailOuter .value {
  font-size: 15px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.filterDrop.searchBy.filterWrap select,
.filterDrop.searchBy.filterWrap input {
  min-height: 40px;
  border-radius: 10px;
  border: solid 1px #f1f1f1;
  width: 100%;
}

.filterWrap-cls {
  position: absolute;
  background: #fff;
  min-width: 250px;
  box-shadow: 0 0 10px #00000014;
  padding: 20px;
  z-index: 111;
  top: 100%;
  border-radius: 10px;
}

.filterWrap-cls select,
.filterWrap-cls input {
  width: 100%;
  padding: 0 10px !important;
  min-height: 40px;
  margin-bottom: 20px;
  border-radius: 7px;
  border: solid 1px #f1f1f1;
  background: #f1f1f1;
}

.filterWrap-cls .border-bottom {
  border: 0 !important;
}

.filterWrap-cls .border-bottom button {
  border-radius: 7px;
  display: inline-flex;
  margin-right: 10px;
  border: solid 1px #f1f1f1;
  padding: 7px 30px;
}

.splash-screen {
  position: relative;
  height: 100vh;
}

.logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.googleMap {
  height: 25vh;
  margin-bottom: 10px;
  overflow: hidden;
  border-radius: 8px;
  /* position: relative; */
  width: 100%;
}

.autocomplete-dropdown-container {
  position: absolute;
  z-index: 111;
  width: 100%;
  padding: 6px;
  background: #fff;
  border-radius: 0 0 8px 8px;
  display: flex;
  flex-direction: column;
  gap: 6px;
  border: 1px solid #f1f1f1;
}

a.bottam-icon img {
  width: 20px;
  height: 20px;
  filter: invert(6);
}

a.bottam-icon {
  width: 40px;
  height: 40px;
  background-color: #0aa9dd;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
ul.footer-bottam {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0px 20px;
  gap: 10px;
  padding-bottom: 10px;
}
.last-footer {
  background-color: azure;
}
ul.footer-bottam li {
  list-style: none;
}
span.active-log {
  width: 8px;
  height: 8px;
  background-color: #1fcb00;
  position: absolute;
  right: 0px;
  top: 0px;
  border-radius: 50%;
}
span.notify-log {
  position: absolute;
  width: 15px;
  height: 15px;
  background-color: #0aa9dd;
  right: -9px;
  color: #fff;
  font-size: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  top: 0;
}
.sliderOuter {
  max-height: 80vh !important;
}
.sliderItem, .sliderItem img {
  max-height: 80vh !important;
}